export const DEFAULT_DATE_FORMAT = "dd.mm.yyyy HH:MM:ss";
export const EMPTY_STRING = "--";

export enum Intervals {
  "30_SEC" = 0.5 * 60 * 1000, // 30 sec - debug only
  "5_MIN" = 5 * 60 * 1000, // 5 min
  "15_MIN" = 15 * 60 * 1000, // 15 min
  "30_MIN" = 30 * 60 * 1000, // 30 min
  "60_MIN" = 60 * 60 * 1000, // 60 min
  "24_H" = 24 * 60 * 60 * 1000, // 24 hours
}

export enum Periods {
  ONE_HOUR = 1 * 60 * 60 * 1000,
  ONE_DAY = 24 * 60 * 60 * 1000,
  ONE_WEEK = 7 * 24 * 60 * 60 * 1000,
  ONE_MONTH = 30 * 24 * 60 * 60 * 1000,
  THREE_MONTHS = 90 * 24 * 60 * 60 * 1000,
}

export const DEFAULT_PERIOD = Periods.ONE_WEEK;
