import { IEntity } from "../@shared/types";
import { HttpClient } from "../utils/HttpClient";

export const updateEntities = (entities: IEntity[]) => {
  const requestId = new Date().getTime();
  return HttpClient.instance.post<IEntity[]>(requestId, "entity/update", entities);
};

export const removeEntities = (entities: IEntity[]) => {
  const requestId = new Date().getTime();
  return HttpClient.instance.post<number>(requestId, "entity/delete", entities);
};