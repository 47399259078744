export const routes = {
  home: "/",
  availability: "/availability",
  availabilityTests: "/availability-tests",
  ssl: "/ssl",
  sslTests: "/ssl-tests",
  login: "/login",
  register: "/register",
  monitorStatus: "/monitor-status",
  taskHistory: "/task-history",
  users: "/users",
  userPasswordReset: "/password-reset"
};
