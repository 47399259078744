import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IEntity } from "../@shared/types";
import React, { FunctionComponent } from "react";
import { dateFormatter } from "../utils/DateFormatter";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { CustomInput } from "reactstrap";

export const EntityInfo: FunctionComponent<{ entity: IEntity }> = ({ entity }) => {
    return (
        <>
            <div className="card p-2 box box-h box-spaced box-middle box-justified mb-3 bg-light">
                <div className="box box-h box-spaced box-middle">
                    <FontAwesomeIcon icon={faInfoCircle} size={"lg"} />
                    <small className="box box-v text-secondary">
                        <div>Data stworzenia: <span className="text-nowrap">{dateFormatter.format(entity.created_on)}</span></div>
                        <div>Data zmiany: <span className="text-nowrap">{dateFormatter.format(entity.updated_on)}</span></div>
                    </small>
                </div>
                <div className="box box-h box-middle">
                    <CustomInput id="active" type="checkbox" checked={entity.active} disabled />
                    <small>Aktywny</small>
                </div>
            </div>
        </>
    );
};