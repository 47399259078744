import { HttpClient } from "../utils/HttpClient";
import { TAuthData } from "../Types";
import { IUser } from "../@shared/types";

export const registerUser = async (userData: TAuthData) => {
  const requestId = new Date().getTime();
  await HttpClient.instance.post(requestId, "user/register", userData, false);
};

export const loginUser = async (userData: TAuthData) => {
  const requestId = new Date().getTime();
  await HttpClient.instance.post(requestId, "user/login", userData, false);
};

export const getUserForSession = async () => {
  const requestId = new Date().getTime();
  return HttpClient.instance.get<IUser>(requestId, "user");
}

export const logoutUser = async () => {
  const requestId = new Date().getTime();
  await HttpClient.instance.post(requestId, "user/logout", null);
};

export const requestPasswordReset = async (email: string) => {
  const requestId = new Date().getTime();
  await HttpClient.instance.post(requestId, "user/request-reset-password", { email }, false);
}

export const passwordReset = async (userId: number, resetToken: string, password: string) => {
  const requestId = new Date().getTime();
  await HttpClient.instance.post(requestId, "user/reset-password", { userId, resetToken, password }, false);
}