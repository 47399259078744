import React from "react";
import { IEvent, IFeatureEventProperties, IFeatureTest, IUrl } from "../@shared/types";

export type TCacheItem<T, K> = {
    data: T[],
    reload: () => void,
    item: (key: K) => T,
    items: (keys: K[]) => T[]
};

export type TCacheContext = {
    tests: TCacheItem<IFeatureTest, number>
    urls: TCacheItem<IUrl, number>
    unreadEvents: TCacheItem<IEvent<IFeatureEventProperties>, number>
}

export const CacheContext = React.createContext<TCacheContext>(null);