import React, { FunctionComponent } from "react";
import { IAvailabilityStateProperties, IFeatureState, State, TAvailabilityBreakdown } from "../../../../@shared/types";
import _ from "lodash";
import { durationHumanizer } from "../../../../utils/DateDurationHumanizer";
import CardCounter from "../../../CardCounter";
import { faHeartbeat } from "@fortawesome/free-solid-svg-icons";

const Summary: FunctionComponent<{
    statesHistory: IFeatureState<IAvailabilityStateProperties>[],
    breakdowns: TAvailabilityBreakdown[]
}> = ({ statesHistory, breakdowns }) => {
    const availabilityFactor = statesHistory.length > 0 ? statesHistory.filter(e => e.state === State.UP).length / statesHistory.length : 0;
    const breakdownsTimeSumMs = _.sum(breakdowns.map(e => (e.recovered_on ?? new Date()).getTime() - e.detected_on.getTime()))

    return (
        <div className="counter-wrapper">
            <CardCounter text="Dostępność" type="success" faIcon={faHeartbeat} value={(availabilityFactor * 100).toFixed(2) + "%"} layout="v" />
            <CardCounter text="Liczba okresów niedostępności" type="danger" value={breakdowns.length} layout="v" />
            <CardCounter text="Czas okresów niedostępności" type="danger" value={durationHumanizer.humanize(breakdownsTimeSumMs)} layout="v" />
        </div>
    );
};

export default Summary;