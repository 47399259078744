import axios from "axios";
import { sanitize } from "../@shared/Sanitize";
import { AppConfig } from "../AppConfig";

class HttpError extends Error {
  constructor(message?: string, public statusCode = 500) {
    super(message);
    this.name = "HttpError";
  }
}

export function HttpMethod() {
  return function (target: HttpClient, propertyKey: string, descriptor: PropertyDescriptor) {
    const method = descriptor.value;

    descriptor.value = async function (...args: any[]) {
      const [requestId, path] = args;
      console.log(`HTTP request id: ${requestId}, method: ${method.name.toUpperCase()}, path: ${path}`);

      if (!HttpClient.$abortControllerMap.has(requestId))
        HttpClient.$abortControllerMap.set(requestId, new AbortController());

      try {
        const result = await method.apply(this, args);
        return sanitize(result);
      }
      catch (ex) {
        const message = ex.response?.data?.message || ex.message || ex;
        if (message === "canceled")
          return console.warn(`HTTP request id: ${requestId} canceled`);

        throw new HttpError(message, ex.request.status);
      }
      finally {
        HttpClient.$abortControllerMap.delete(requestId);
      }
    };

    return descriptor;
  };
}

interface IHttpClient {
  get<T>(requestId: number, path: string, auth?: boolean, headers?: {}): Promise<T>;
  post<T>(requestId: number, path: string, payload: any, auth?: boolean, headers?: {}): Promise<T>;
  put<T>(requestId: number, path: string, payload: any, number, auth?: boolean, headers?: {}): Promise<T>;
  patch<T>(requestId: number, path: string, payload: any, auth?: boolean, headers?: {}): Promise<T>;
  delete<T>(requestId: number, path: string, auth?: boolean, headers?: {}): Promise<T>;
}

export class HttpClient implements IHttpClient {
  private static _instance: HttpClient = null;

  static get instance() {
    if (!this._instance)
      this._instance = new HttpClient();

    return this._instance;
  }

  static $abortControllerMap = new Map<number, AbortController>();

  get baseUrl() {
    const isDevEnv = !process.env.NODE_ENV || process.env.NODE_ENV === "development";
    return isDevEnv ? "/api" : `${AppConfig.instance.apiUrl}/api`;
  }

  @HttpMethod()
  async get<T = any>(requestId: number, path: string, auth = true, headers = {}) {
    const res = await axios.get<T>(`${this.baseUrl}/${path}`, {
      headers,
      signal: HttpClient.$abortControllerMap.get(requestId).signal,
      withCredentials: auth
    });

    return res.data;
  }

  @HttpMethod()
  async post<T = any>(requestId: number, path: string, payload: any, auth = true, headers = {}) {
    const res = await axios.post<T>(`${this.baseUrl}/${path}`, payload, {
      headers,
      signal: HttpClient.$abortControllerMap.get(requestId).signal,
      withCredentials: auth
    });

    return res.data;
  }

  @HttpMethod()
  async put<T = any>(requestId: number, path: string, payload: any, auth = true, headers = {}) {
    const res = await axios.put<T>(`${this.baseUrl}/${path}`, payload, {
      headers,
      signal: HttpClient.$abortControllerMap.get(requestId).signal,
      withCredentials: auth
    });

    return res.data;
  }

  @HttpMethod()
  async patch<T = any>(requestId: number, path: string, payload: any, auth = true, headers = {}) {
    const res = await axios.patch<T>(`${this.baseUrl}/${path}`, payload, {
      headers,
      signal: HttpClient.$abortControllerMap.get(requestId).signal,
      withCredentials: auth
    });

    return res.data;
  }

  @HttpMethod()
  async delete<T = any>(requestId: number, path: string, auth = true, headers = {}) {
    const res = await axios.delete<T>(`${this.baseUrl}/${path}`, {
      headers,
      signal: HttpClient.$abortControllerMap.get(requestId).signal,
      withCredentials: auth
    });

    return res.data;
  }

  cancelRequest(requestId: number) {
    if (HttpClient.$abortControllerMap.has(requestId)) {
      HttpClient.$abortControllerMap.get(requestId).abort();
      HttpClient.$abortControllerMap.delete(requestId); // cleanup
    }
  }
}