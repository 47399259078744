import { faCheck, faClock, faExclamation, faRocket, faTasks, faTimes } from "@fortawesome/free-solid-svg-icons";
import React, { FunctionComponent } from "react";
import DefaultTemplate from "../templates/Default";
import DataTable from "../components/DataTable/DataTable";
import { dateFormatter } from "../utils/DateFormatter";
import { EMPTY_STRING } from "../Constants";
import AppBadge from "./Badge";
import { ITaskHistory, TaskStatus } from "../@shared/types";
import { usePagedFeed } from "../hooks/useFeed";

const TaskHistoryList: FunctionComponent = ({
}) => {
    const feed = usePagedFeed<ITaskHistory[]>("task-history");

    return (
        <DefaultTemplate title="Historia wykonania zadań" faIcon={faTasks}>
            <DataTable
                feed={feed}
                searchBy={"code"}
                rowKeyFn={e => e.id}
                columnConfig={[
                    {
                        key: "code",
                        type: "text",
                        title: "Kod",
                        renderFn: (e: ITaskHistory) => e.code
                    },
                    {
                        key: "status",
                        type: "task-status",
                        title: "Status",
                        renderFn: (e: ITaskHistory) => {
                            switch (e.status) {
                                case TaskStatus.QUEUED:
                                    return <AppBadge color="secondary" faIcon={faClock} css={"badge-icon"} text={"Oczekuje"} />;

                                case TaskStatus.RUNNING:
                                    return <AppBadge color="info" faIcon={faRocket} css={"badge-icon"} text={"Uruchomiony"} />;

                                case TaskStatus.SUCCESS:
                                    return <AppBadge color="success" faIcon={faCheck} css={"badge-icon"} text={"Zakończony"} />;

                                case TaskStatus.WARNING:
                                    return <AppBadge color="warning" faIcon={faExclamation} css={"badge-icon"} text={"Ostrzeżenie"} />;

                                case TaskStatus.FAILURE:
                                    return <AppBadge color="danger" faIcon={faTimes} css={"badge-icon"} text={"Niepowodzenie"} />;

                                default:
                                    return EMPTY_STRING;
                            }
                        }
                    },
                    {
                        key: "started_on",
                        type: "date",
                        title: "Data uruchomienia",
                        renderFn: (e: ITaskHistory) => dateFormatter.format(e.started_on)
                    },
                    {
                        title: "Czas trwania",
                        renderFn: (e: ITaskHistory) => dateFormatter.duration(e.started_on, e.finished_on ?? new Date(), true)
                    },
                    {
                        key: "message",
                        type: "text",
                        title: "Wiadomość zwrotna",
                        renderFn: (e: ITaskHistory) => e.message ?? EMPTY_STRING,
                        options: { maxWidth: "400px", preformatted: true }
                    }
                ]}
            />
        </DefaultTemplate>
    );
};

export default TaskHistoryList;
