import humanizeDuration from "humanize-duration";

export class DateDurationHumanizer {
  constructor(private _durationHumanizerFn: humanizeDuration.Humanizer) {}

  humanize(timeMs: number) {
    return this._durationHumanizerFn(timeMs);
  }
}

export const durationHumanizer = new DateDurationHumanizer(
  humanizeDuration.humanizer({
    language: "shortPl",
    round: true,
    languages: {
      shortPl: {
        y: () => "lat",
        mo: () => "mies",
        w: () => "tyg",
        d: () => "dni",
        h: () => "godz",
        m: () => "min",
        s: () => "s",
        ms: () => "ms",
      },
    },
    largest: 2,
  }),
);
