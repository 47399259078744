import { faEyeSlash, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormikErrors, FormikHandlers } from "formik";
import React, { FunctionComponent, useState } from "react";
import { Input, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";

type TPasswordInputProps = {
    error: string | string[] | FormikErrors<any> | FormikErrors<any>[],
    value: string,
    name: string
} & Partial<FormikHandlers>;

export const PasswordInput: FunctionComponent<TPasswordInputProps> = ({ handleChange, handleBlur, error, value, name }) => {
    const [showPassword, setShowPassword] = useState(false);
    return (
        <InputGroup>
            <Input
                type={showPassword ? "text" : "password"}
                name={name}
                id={name}
                onChange={handleChange}
                onBlur={handleBlur}
                value={value}
                invalid={error != null}
                placeholder={"Hasło"}
            />
            <InputGroupAddon
                addonType="append"
                onClick={() => setShowPassword(!showPassword)}
                title="Pokaż hasło"
                className="clickable"
            >
                <InputGroupText>
                    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                </InputGroupText>
            </InputGroupAddon>
        </InputGroup>
    )
}

export default PasswordInput;