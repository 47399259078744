import { useState, useEffect } from "react";
import { HttpClient } from "../utils/HttpClient";

export type TFetch<T> = {
    response: T,
    error: string,
    pending: boolean
    reload: () => void
}

export const useFetch = <T = any>(path: string, triggers = [], canTriggerFn?: () => boolean, initialValue = null): TFetch<T> => {
    const
        [response, setResponse] = useState<T>(initialValue),
        [error, setError] = useState<string>(null),
        [pending, setPending] = useState(false),
        [reloadFlag, setReloadFlag] = useState(false);

    useEffect(() => {
        const shouldSkip = typeof canTriggerFn === "function" && !canTriggerFn();
        if (!shouldSkip) {
            const requestId = new Date().getTime();
            setPending(true);
            HttpClient.instance.get(requestId, path)
                .then(data => setResponse(data))
                .catch(err => setError(err.message ?? err))
                .finally(() => setPending(false));
        }
    }, [reloadFlag, ...triggers]);

    return {
        response,
        error,
        pending,
        reload: () => setReloadFlag(!reloadFlag)
    };
}