import { CacheContext, TCacheContext, TCacheItem } from "../context/CacheContext";
import { useContext, useReducer, useCallback, useMemo } from "react";
import { HttpClient } from "../utils/HttpClient";
import { IAvailabilityTestProperties, IEvent, IFeatureTest, IUrl } from "../@shared/types";

export class Cache<T, K> {
    private _data: T[] = [];

    constructor(private _key: string, private _feedFn: () => Promise<T[]>, private _keyFn: (e: T) => K) { }

    item(key: K) {
        const item = this._data.find(e => this._keyFn(e) === key);
        return item;
    }

    items(keys: K[]) {
        return keys.map(e => this.item(e));
    }

    async reload() {
        console.log(`Refreshing cache item: ${this._key}`);
        this._data = await this._feedFn();
    }

    get data() {
        return this._data;
    }
}

export const useCacheItem = <T, K>(key: string, feedFn: () => Promise<T[]>, keyFn: (e) => K): TCacheItem<T, K> => {
    const cache = useMemo(() => new Cache<T, K>(key, feedFn, keyFn), []);

    const [data, dispatch] = useReducer((state: T[], action: "reload") => {
        return action === "reload" ? cache.data : state;
    }, cache.data);

    const reloadCache = useCallback(async () => {
        await cache.reload();
        dispatch("reload");
    }, []);

    return {
        data,
        reload: reloadCache,
        item: (key: any) => cache.item(key),
        items: (keys: any[]) => cache.items(keys)
    };
}

export const useCacheContext = () => ({
    tests: useCacheItem(
        "tests",
        async () => [
            ...await HttpClient.instance.get<IFeatureTest<IAvailabilityTestProperties>[]>(new Date().getTime(), "availability/tests"),
            ...await HttpClient.instance.get<IFeatureTest[]>(new Date().getTime(), "ssl/tests")
        ],
        e => e.id
    ),
    urls: useCacheItem(
        "urls",
        () => HttpClient.instance.get<IUrl[]>(new Date().getTime(), "url"),
        e => e.id
    ),
    unreadEvents: useCacheItem(
        "events",
        async () => {
            const events = await HttpClient.instance.get<IEvent[]>(new Date().getTime(), "events");
            return events.filter(e => !e._read);
        },
        e => e.id
    )
} as TCacheContext);

export const useCache = () => useContext(CacheContext);