import { faGlobe, faLock, faPlus, faTrash, faCog, faEye } from "@fortawesome/free-solid-svg-icons";
import React, { FunctionComponent, useCallback, useContext } from "react";
import DefaultTemplate from "../../templates/Default";
import DataTable from "../../components/DataTable/DataTable";
import AppBadge from "../../components/Badge";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "../@UI/Services";
import { FeatureType, IAvailabilityTestProperties, IFeatureTest } from "../../@shared/types";
import withEntityEdit, { TEditEntityHOCProps } from "../../hocs/withEntityEdit";
import Edit from "./Edit";
import { IUrl } from "../../@shared/types";
import withTestsData from "../../hocs/withTestsData";
import { UrlHelper } from "./Helper";
import { useCache } from "../../hooks/useCache";
import { usePagedFeed } from "../../hooks/useFeed";
import { AvailabilityTest, Entity } from "../../utils/Entity";
import { SessionContext } from "../../context/SessionContext";
import { UrlProperties } from "./UrlProperties";
import { UrlOverview } from "./UrlOverview";
import { updateEntities } from "../../actions/entity";
import { autoSync } from "../../actions/test";
import { Toast } from "../@UI/Services";

const UrlList: FunctionComponent<TEditEntityHOCProps> = ({
  removeEntities
}) => {
  const cache = useCache();
  const feed = usePagedFeed<IUrl>("url");
  const session = useContext(SessionContext);

  return (
    <DefaultTemplate title="Status monitorowanych adresów URL" faIcon={faGlobe}>
      <DataTable
        feed={feed}
        searchBy={"url"}
        rowKeyFn={e => e.id}
        options={{ selectableRows: true }}
        columnConfig={[
          {
            key: "url",
            type: "text",
            title: "Adres URL",
            renderFn: e => (
              <a href={e.url} title="Zobacz stronę" target="_blank" rel="noopener noreferrer">
                {e.url}
              </a>
            ),
          },
          {
            title: "",
            renderFn: (e: IUrl) => <UrlProperties props={e.properties} />
          },
          {
            title: "Monitor dostępności",
            renderFn: e =>
              UrlHelper.isMonitorEnabled(e.id, cache.tests.data, FeatureType.Availability) ? (
                <AppBadge color="success" text={"Włączony"} />
              ) : (
                <AppBadge color="danger" text={"Wyłączony"} />
              ),
          },
          {
            title: "Monitor certyfikatów SSL",
            renderFn: e =>
              UrlHelper.isMonitorEnabled(e.id, cache.tests.data, FeatureType.Ssl) ? (
                <AppBadge color="success" text={"Włączony"} />
              ) : (
                <AppBadge color="danger" text={"Wyłączony"} />
              ),
          },
        ]}
        topComponents={[
          <Button
            key="addUrl"
            color="primary"
            onClick={() => {
              Modal.open(
                <Edit
                  entity={Entity.create<IUrl>("Url", { user_id: session.user.id })}
                  onSubmitFn={async (url) => {
                    // autocreate availability test by default
                    const test = AvailabilityTest.create(session.user.id);
                    test.url_id = url.id;
                    await updateEntities([test]);

                    Modal.closeTop();
                    feed.reload();
                    cache.tests.reload();
                  }}
                ></Edit>,
                {
                  title: "Dodawanie nowego adresu URL",
                },
              );
            }}
          >
            <FontAwesomeIcon icon={faPlus} /> <span className="mob-hidden">Dodaj adres</span>
          </Button>,
        ]}
        rowButtons={[
          {
            color: "danger",
            faIcon: faTrash,
            onClickFn: (e: IUrl) => {
              Modal.confirm(
                "Usuwanie adresu URL",
                `Czy usunąć adres "${e.url}" oraz wszystkie powiązane z nim dane?`,
                () => removeEntities([e]).then(() => feed.reload()),
              );
            },
            title: "Usuń",
            tooltip: "Usuń adres URL",
          },
          {
            color: "info",
            faIcon: faEye,
            onClickFn: (e: IUrl) => {
              Modal.open(<UrlOverview url={e} />, {
                title: `Przegląd adresu ${e.url}`
              });
            },
            title: "Zobacz szczegóły",
          },
        ]}
        batchOperations={{
          addAvailabilityTest: {
            name: "Dodaj do monitora dostępności",
            onSubmitFn: async (urls: IUrl[]) => {
              const tests: IFeatureTest<IAvailabilityTestProperties>[] = [];
              for (const e of urls) {
                const isEnabled = UrlHelper.isMonitorEnabled(e.id, cache.tests.data, FeatureType.Availability);
                if (isEnabled)
                  continue;

                const test = AvailabilityTest.create(session.user.id);
                test.url_id = e.id;
                tests.push(test);
              }

              if (tests.length > 0) {
                await updateEntities(tests);
                Toast.show(`Dodano ${tests.length} nowych adresów do monitora dostępności.`);
                cache.tests.reload();
              }
            }
          },
          addSslTest: {
            name: "Dodaj do monitora certyfikatów SSL",
            onSubmitFn: async () => {
              Modal.confirm(
                "Automatyczna synchronizacja adresów",
                "Adresy wspierające protokół HTTPS zostaną dodane, a niewspierające zostaną usunięte z monitora certyfikatów SSL. Czy chcesz kontynuować?",
                async () => {
                  await autoSync(FeatureType.Ssl);
                  feed.reload();
                  cache.tests.reload();
                });
            }
          }
        }}
      />
    </DefaultTemplate >
  );
};

export default withTestsData(withEntityEdit(UrlList, "urls"));