export class DocumentEventEmitter<T extends string> {
    private _listeners = new Map<string, ((e: CustomEvent) => any)[]>();

    on(ev: T, callback: (data?: any) => void) {
        const listener = (e: CustomEvent) => callback(e.detail);

        const evListeners = this._listeners.get(ev) ?? [];
        evListeners.push(listener);
        this._listeners.set(ev, evListeners);

        document.addEventListener(ev, listener);
    }

    off(ev: T, callback: (data?: any) => void) {
        const evListeners = this._listeners.get(ev);

        const listenerIdx = evListeners.findIndex(e => e === callback);
        const [listener] = evListeners.splice(listenerIdx, 1);

        evListeners.length > 0
            ? this._listeners.set(ev, evListeners)
            : this._listeners.delete(ev);

        document.removeEventListener(ev, listener);
    }

    emit(ev: T, data?: any) {
        document.dispatchEvent(new CustomEvent(ev, { detail: data }));
    }
}