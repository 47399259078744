import React, { FunctionComponent, useState } from "react";
import { Button } from "reactstrap";
import { TColumnConfig } from "./DataTable";
import { FilterItem } from "./FilterItem";

type TFiltersProps = {
    columnConfig: TColumnConfig[];
    onSetFn: (e: {}) => void;
    filters: {};
}

export const Filters: FunctionComponent<TFiltersProps> = ({ columnConfig, onSetFn, filters: existingFilters }) => {
    const [filters, setFilters] = useState(existingFilters);

    return (
        <div className="datatable-filters box box-v box-fill box-spaced">
            <div className="box box-v box-fill box-spaced scroll">
                {columnConfig.filter(e => !e.options?.noFilter && e.type).map(e => {
                    return <FilterItem key={e.key} title={e.title} type={e.type} value={existingFilters[e.key]} onChangeFn={v => {
                        setFilters({
                            ...filters,
                            [e.key]: v
                        })
                    }} />
                })}
            </div>

            <div className="box box-h box-spaced box-justified">
                <Button color="primary" outline onClick={() => setFilters({})}>
                    Czyść
                </Button>
                <Button color="primary" onClick={() => onSetFn(filters)}>
                    Wybierz
                </Button>
            </div>
        </div>
    );
}