import { faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import React, { FunctionComponent, useMemo } from "react";
import { Card, CardBody } from "reactstrap";

const ResponseTimeStats: FunctionComponent<{
    values: number[]
}> = ({ values }) => {
    const stats = useMemo(() => ({
        min: (_.min(values) ?? 0).toFixed(2),
        max: (_.max(values) ?? 0).toFixed(2),
        mean: (values.length > 0 ? _.mean(values) : 0).toFixed(2)
    }), [values]);

    return (
        <div className="box box-v box-spaced">
            <Card>
                <CardBody className="text-center">
                    <div>Najmniejszy</div>
                    <span className="text-success font-weight-bold">
                        <FontAwesomeIcon icon={faClock} /> {stats.min} s
                    </span>
                </CardBody>
            </Card>
            <Card>
                <CardBody className="text-center">
                    <div>Średni</div>
                    <span className="text-warning font-weight-bold">
                        <FontAwesomeIcon icon={faClock} /> {stats.mean} s
                    </span>
                </CardBody>
            </Card>
            <Card>
                <CardBody className="text-center">
                    <div>Największy</div>
                    <span className="text-danger font-weight-bold">
                        <FontAwesomeIcon icon={faClock} /> {stats.max} s
                    </span>
                </CardBody>
            </Card>
        </div>
    )
};

export default ResponseTimeStats;