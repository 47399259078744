import React, { FunctionComponent } from "react";

const Logo: FunctionComponent = () => (
  <div className="app-logo">
    <h1 className="text-center">
      <span className="text-danger">
        <b>Web</b>
      </span>
      Alert
    </h1>
    <div className="text-center">Monitor usług internetowych</div>
  </div>
);

export default Logo;
