import React, { FunctionComponent, useEffect, useState } from "react";
import { Input, InputGroupAddon, InputGroup, InputGroupText } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";

type TSearchbarProps = {
  placeholder?: string;
  onSearch: (searchText: string) => void;
};

export const Searchbar: FunctionComponent<TSearchbarProps> = ({
  placeholder = "Szukaj...",
  onSearch,
}) => {
  const [searchText, setSearchText] = useState<string>("");

  useEffect(() => {
    const v = searchText.toLowerCase().substr(0, 20);
    onSearch(v);
  }, [searchText]);

  return (
    <div className="box box-h">
      <InputGroup>
        <InputGroupAddon addonType="prepend">
          <InputGroupText>
            <FontAwesomeIcon icon={faSearch} />
          </InputGroupText>
        </InputGroupAddon>

        <Input
          onChange={e => setSearchText(e.target.value)}
          placeholder={placeholder}
          value={searchText}
        />

        {searchText !== "" && (
          <InputGroupAddon
            addonType="append"
            onClick={() => setSearchText("")}
            title="Wyczyść"
            className="clickable"
          >
            <InputGroupText>
              <FontAwesomeIcon icon={faTimes} />
            </InputGroupText>
          </InputGroupAddon>
        )}
      </InputGroup>
    </div>
  );
};
