import React, { FunctionComponent } from "react";
import { Formik } from "formik";
import {
  CustomInput,
  Label,
  Input,
  Form,
  FormGroup,
  FormText,
  Button,
} from "reactstrap";

import { Intervals } from "../../../Constants";
import withEntityEdit, { TEditEntityHOCProps } from "../../../hocs/withEntityEdit";
import { IAvailabilityTestProperties, IFeatureTest } from "../../../@shared/types";

const EditBatchTest: FunctionComponent<{
  tests: IFeatureTest<IAvailabilityTestProperties>[],
  onSubmitFn: () => void;
} & TEditEntityHOCProps> = ({
  tests,
  onSubmitFn,
  updateEntities
}) => {
    return (
      <Formik
        initialValues={{
          interval_ms: tests[0].interval_ms,
          properties: {
            breakdownTimeTolerance: tests[0].properties.breakdownTimeTolerance,
            notifyDisabled: true,
            notifyUserEmails: []
          }
        } as IFeatureTest<IAvailabilityTestProperties>}
        onSubmit={async values => {
          const updatedTests = tests.map(e => ({ ...e, ...values }));
          await updateEntities(updatedTests);
          onSubmitFn();
        }}
      >
        {({ values, errors, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
          <Form>
            <FormGroup>
              <Label for="interval">Częstotliwość sprawdzania</Label>
              <Input
                type="select"
                name="interval"
                id="interval"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.interval_ms}
              >
                <option value={Intervals["5_MIN"]}>5 min</option>
                <option value={Intervals["15_MIN"]}>15 min</option>
                <option value={Intervals["30_MIN"]}>30 min</option>
                <option value={Intervals["60_MIN"]}>60 min</option>
              </Input>
            </FormGroup>

            <FormGroup>
              <Label for="breakdownTimeTolerance">Czas tolerancji okresu niedostępności</Label>
              <CustomInput
                type="range"
                name="breakdownTimeTolerance"
                id="breakdownTimeTolerance"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.properties.breakdownTimeTolerance}
                min={0}
                max={60 * 60 * 1000}
                step={15 * 60 * 1000}
              />
              <div className="box box-h box-justified">
                <div>Żaden</div>
                <div>15 min</div>
                <div>30 min</div>
                <div>45 min</div>
                <div>60 min</div>
              </div>
              <FormText>
                Okresy niedostępności nie będą raportowane, jeżeli czas ich trwania będzie krótszy niż czas tolerancji.
              </FormText>
            </FormGroup>

            <FormGroup>
              <Label>Dodatkowi odbiorcy powiadomień</Label>
              <div className="box box-v box-spaced">
                {[1, 2, 3].map((e, i) =>
                  <Input
                    key={`email-${i}`}
                    type="text"
                    name={`properties.notifyUserEmails[${i}]`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.properties?.notifyUserEmails?.[i]}
                  />
                )}
              </div>
              <FormText>
                Dodatkowe adresy email, na które będą wysyłane powiadomienia.
              </FormText>
            </FormGroup>

            <FormGroup className="box box-h">
              <CustomInput
                type="switch"
                name="properties.notifyDisabled"
                id="properties.notifyDisabled"
                checked={!values.properties.notifyDisabled}
                onChange={() => setFieldValue("properties.notifyDisabled", !values.properties.notifyDisabled)}
              />
              <Label>Włącz powiadomienia</Label>
            </FormGroup>

            <div className="box box-h box-end">
              <Button
                color="primary"
                onClick={() => handleSubmit()}
                disabled={Object.keys(errors).length > 0}
              >
                Zapisz
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    );
  };

export default withEntityEdit(EditBatchTest, "tests");
