import React, { FunctionComponent, useEffect, useState } from "react";
import { SessionContext, TSessionContextProps } from "../context/SessionContext";
import { IUser } from "../@shared/types";
import { getUserForSession } from "../actions/user";

export default function withUserSessionContext(WrappedComponent: React.ComponentType<any>) {
  const UserSessionWrapper: FunctionComponent<any> = ({ ...props }) => {
    const [user, setUser] = useState<IUser>(null);
    const [session, setSession] = useState<TSessionContextProps>({
      user: user,
      setUser: e => setUser(e)
    });

    useEffect(() => {
      setSession({ ...session, user });
    }, [user]);

    useEffect(() => {
      getUserForSession()
        .then(e => session.setUser(e))
        .catch(e => session.setUser(null));
    }, []);

    return (
      <SessionContext.Provider value={session}>
        <WrappedComponent {...props} />
      </SessionContext.Provider>
    );
  }

  return UserSessionWrapper;
};