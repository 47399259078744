import React, { FunctionComponent } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

export type TCardCounterType = "success" | "primary" | "danger" | "warning" | "secondary" | "light";

type TCardCounterProps = {
  key?: any;
  type?: TCardCounterType;
  value: number | string;
  text?: string;
  faIcon?: IconProp;
  layout?: "h" | "v"
};

const CardCounter: FunctionComponent<TCardCounterProps> = ({
  type = "secondary",
  value,
  text,
  faIcon = faInfoCircle,
  layout = "h"
}) => (
  <div className={`counter shadow-sm ${layout === "h" ? 'counter-horizontal' : 'counter-vertical'}`}>
    <div className="counter-inner">
      <div className="counter-value">{value ?? 0}</div>
      <div className="counter-info">{text}</div>
    </div>
    <div className={`counter-icon bg-${type}`}>
      <FontAwesomeIcon icon={faIcon} size="2x" />
    </div>
  </div>
);

export default CardCounter;
