import { Intervals } from "../Constants";
import { FeatureType, IAvailabilityTestProperties, IEntity, IFeatureTest } from "../@shared/types";

export class Entity {
    static create<T extends IEntity>(_t: string, data: Partial<T> = {}): T {
        const entity = data;

        // defaults
        entity.t = _t;

        return <any>entity;
    }
}

export class AvailabilityTest {
    static create(userId: number) {
        return Entity.create<IFeatureTest<IAvailabilityTestProperties>>("FeatureTest", {
            type: FeatureType.Availability,
            user_id: userId,
            interval_ms: Intervals["30_MIN"],
            properties: {
                breakdownTimeTolerance: Intervals["30_MIN"]
            }
        });
    }
}