import { FeatureType, IFeatureTest, IUrl } from "../../@shared/types";

export class UrlHelper {
    static isMonitorEnabled(urlId: number, tests: IFeatureTest[], testType: FeatureType) {
        return tests.some(e => e.type === testType && e.url_id === urlId);
    }

    static getNotUsed(testType: FeatureType, tests: IFeatureTest[], urls: IUrl[]) {
        const usedIds = tests.filter(e => e.type === testType).map(e => e.url_id);
        return urls.filter(e => !usedIds.includes(e.id));
    }
}