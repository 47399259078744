import React, { ReactElement } from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";

import "./styles/index.scss";

import Root from "./Root";
import { AppConfig } from "./AppConfig";

fetch("config.json")
  .then(response => response.json())
  .then(data => {
    AppConfig.set(data);
    return <Root />;
  })
  .then((el: ReactElement) => ReactDOM.render(el, document.getElementById("root")))
  .catch(err => console.error(`Cannot load config file -> ${err.message || err}.`));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
