import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { routes } from "./routes";

import PrivateRoute from "./components/PrivateRoute";
import AdminRoute from "./components/AdminRoute";

import Main from "./Main";
import AvailabilityCockpit from "./components/feature/availability/Cockpit";
import AvailabilityTestList from "./components/feature/availability/TestList";
import SslCockpit from "./components/feature/ssl/Cockpit";
import SslTestList from "./components/feature/ssl/TestList";
import AuthPage from "./AuthPage";
import MonitorStatus from "./components/Url/List";
import TaskHistoryList from "./components/TaskHistory";
import { UserList } from "./components/UserList";
import UserPasswordReset from "./components/UserPasswordReset";

const Root = () => {
  return (
    <div className="app-dock box box-v">
      <BrowserRouter>
        <Main>
          <Switch>
            <Route path={routes.login} render={() => <AuthPage authType="login" />} />
            <Route path={routes.register} render={() => <AuthPage authType="register" />} />
            <Route path={routes.userPasswordReset} component={UserPasswordReset} />
            <Route exact path={routes.home} render={() => <Redirect to={routes.availability} />} />

            <PrivateRoute path={routes.availability} component={AvailabilityCockpit} />
            <PrivateRoute path={routes.availabilityTests} component={AvailabilityTestList} />
            <PrivateRoute path={routes.ssl} component={SslCockpit} />
            <PrivateRoute path={routes.sslTests} component={SslTestList} />

            <PrivateRoute path={routes.monitorStatus} component={MonitorStatus} />
            <PrivateRoute path={routes.taskHistory} component={TaskHistoryList} />
            <AdminRoute path={routes.users} component={UserList} />
          </Switch>
        </Main>
      </BrowserRouter>
    </div>
  );
};

export default Root;
