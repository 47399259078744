import React, { FunctionComponent, useContext } from "react";
import { Formik } from "formik";
import { Button, Label, Input, Form, FormGroup } from "reactstrap";
import { Intervals } from "../../../Constants";
import { TEditTestProps } from "../../../components/feature/availability/EditTest";
import withEntityEdit from "../../../hocs/withEntityEdit";
import Selector from "../../../components/@UI/Selector/Selector";
import { TestTypeContext } from "../../../context/TestTypeContext";
import { UrlHelper } from "../../../components/Url/Helper";
import { useCache } from "../../../hooks/useCache";
import { EntityInfo } from "../../../components/EntityInfo";
import { IFeatureTest } from "../../../@shared/types";

const EditTest: FunctionComponent<TEditTestProps<IFeatureTest>> = ({
  updateEntities,
  onSubmitFn,
  entity
}) => {
  const testType = useContext(TestTypeContext);
  const cache = useCache();

  return (
    <Formik
      initialValues={entity}
      validate={values => {
        const errors: { [key in keyof IFeatureTest]?: string } = {};

        if (!values.url_id)
          errors.url_id = "URL jest wymagany.";

        return errors;
      }}
      onSubmit={async values => {
        await updateEntities([values]);
        onSubmitFn();
      }}
    >
      {({ values, errors, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
        <Form>
          <FormGroup>
            <Label>Adres URL strony</Label>
            <Selector
              items={UrlHelper.getNotUsed(testType, cache.tests.data, cache.urls.data)}
              selected={values.url_id ? [cache.urls.item(values.url_id)] : []}
              displayFn={e => e.url}
              keyFn={e => e.id}
              onSelect={e => setFieldValue("url_id", e?.id)}
              single
              disabled={entity.id != null}
            />
            {errors.url_id && <small className="text-danger">{errors.url_id}</small>}
          </FormGroup>

          <FormGroup>
            <Label for="interval">Częstotliwość sprawdzania</Label>
            <Input
              type="select"
              name="interval"
              id="interval"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.interval_ms}
              disabled
            >
              <option value={Intervals["60_MIN"]}>1 godzina</option>
            </Input>
          </FormGroup>

          <EntityInfo entity={entity} />

          <div className="box box-h box-end">
            <Button
              color="primary"
              onClick={() => handleSubmit()}
              disabled={Object.keys(errors).length > 0}
            >
              Zapisz
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default withEntityEdit(EditTest, "tests");