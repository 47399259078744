import { faEye, faUsers } from "@fortawesome/free-solid-svg-icons";
import { usePagedFeed } from "../hooks/useFeed";
import { IUser } from "../@shared/types";
import React, { FunctionComponent } from "react";
import DefaultTemplate from "../templates/Default";
import { dateFormatter } from "../utils/DateFormatter";
import DataTable from "./DataTable/DataTable";
import { Modal } from "./@UI/Services";
import { UserDetails } from "./UserDetails";

export const UserList: FunctionComponent = () => {
    const feed = usePagedFeed<IUser[]>("user/all");

    return (
        <DefaultTemplate title="Użytkownicy" faIcon={faUsers}>
            <DataTable
                feed={feed}
                searchBy={"email"}
                rowKeyFn={e => e.id}
                columnConfig={[
                    {
                        title: "ID",
                        renderFn: (e: IUser) => e.id
                    },
                    {
                        title: "Adres email",
                        renderFn: (e: IUser) => e.email
                    },
                    {
                        title: "Data utworzenia",
                        renderFn: (e: IUser) => dateFormatter.format(e.created_on)
                    },
                    {
                        title: "Data ostatniego logowania",
                        renderFn: (e: IUser) => dateFormatter.format(e.last_login_on)
                    },
                    {
                        title: "Data ostatniego błędnego logowania",
                        renderFn: (e: IUser) => dateFormatter.format(e.last_failed_login_on)
                    }
                ]}
                rowButtons={[
                    {
                        faIcon: faEye,
                        color: "info",
                        title: "Przegląd",
                        onClickFn: (e: IUser) => Modal.open(<UserDetails user={e} />, { title: "Przegląd użytkownika" })
                    }
                ]}
            />
        </DefaultTemplate>
    );
};