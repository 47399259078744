import React, { useEffect, useState, FunctionComponent, useContext } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";

import { TestTypeContext } from "./context/TestTypeContext";
import { CacheContext, TCacheItem } from "./context/CacheContext";
import { FeatureType } from "./@shared/types";
import { useCacheContext } from "./hooks/useCache";
import { SessionContext } from "./context/SessionContext";
import withUserSessionContext from "./hocs/withUserSessionContext";
import { ModalRoot } from "./components/@UI/Services/ModalRoot";
import { StickyRoot } from "./components/@UI/Services/StickyRoot";
import { ToastRoot } from "./components/@UI/Services/ToastRoot";
import { ErrorService } from "./ErrorService";

const Main: FunctionComponent<RouteComponentProps> = ({ children, location }) => {
  const [testType, setTestType] = useState(null);
  const session = useContext(SessionContext);
  const cacheContext = useCacheContext();

  useEffect(() => {
    const testTypes = [FeatureType.Availability, FeatureType.Ssl];
    const { pathname } = location;

    const currentTestType = testTypes.find(e => pathname.includes(e));
    setTestType(currentTestType);
  }, [location.pathname, testType]);

  useEffect(() => {
    if (session.user)
      Promise.all(Object.keys(cacheContext).map(e => { // reload all cache
        const cacheItem = cacheContext[e] as TCacheItem<any, any>;
        cacheItem.reload();
      }));
  }, [session.user]);

  window.onunhandledrejection = function (err: any) {
    ErrorService.process(err);
  }

  window.onerror = function (err: any) {
    ErrorService.process(err);
  }

  return (
    <CacheContext.Provider value={cacheContext}>
      <TestTypeContext.Provider value={testType}>
        {children}
        <ModalRoot />
        <ToastRoot />
        <StickyRoot />
      </TestTypeContext.Provider>
    </CacheContext.Provider>
  );
};

export default withUserSessionContext(withRouter(Main));
