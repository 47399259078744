import { ReactNode } from "react";
import { DocumentEventEmitter } from "../../../DocumentEventEmitter";

export class StickyService extends DocumentEventEmitter<"open-sticky" | "close-top-sticky"> {
    open(hostEl: HTMLElement, bodyComp: ReactNode) {
        return this.emit("open-sticky", { hostEl, bodyComp });
    }

    closeTop() {
        return this.emit("close-top-sticky");
    }
};