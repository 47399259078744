import React, { FunctionComponent } from "react";
import { Spinner } from "reactstrap";

type TLoaderProps = {
  pending: boolean;
  align?: "left" | "center";
};

const Loader: FunctionComponent<TLoaderProps> = ({ pending = true, align = "center", children }) => {
  return (
    <>
      {pending ? (
        <div className={`box box-v box-fill ${align === "center" ? 'box-center box-middle' : ''}`}>
          <Spinner color="primary" />
        </div>
      ) : (
        children
      )}
    </>
  );
};

export default Loader;
