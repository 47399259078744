import React, { FunctionComponent, useState } from "react";
import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

type TDefaultTemplateProps = {
  title: string;
  faIcon: IconProp;
};

const DefaultTemplate: FunctionComponent<TDefaultTemplateProps> = ({ children, title, faIcon }) => {
  const [sideOpen, toggleSide] = useState(false);

  return (
    <div className="box box-h box-fill">
      <Sidebar isOpen={sideOpen} />

      <div className="box box-v box-fill">
        <Topbar title={title} faIcon={faIcon} onSideToggle={() => toggleSide(!sideOpen)} sideIsOpen={sideOpen} />
        <div className="content box box-v box-fill box-spaced" onClick={() => {
          if (sideOpen)
            toggleSide(false);
        }}>{children}</div>
      </div>
    </div>
  );
};

export default DefaultTemplate;
