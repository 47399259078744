import * as DateFormatLib from "dateformat";
import { DEFAULT_DATE_FORMAT, EMPTY_STRING, Intervals } from "../Constants";
import { DateDurationHumanizer, durationHumanizer } from "./DateDurationHumanizer";

export class DateFormatter {
  constructor(private _humanizer: DateDurationHumanizer) { }

  elapse(from: Date, to: Date) {
    if (from == null || to == null)
      return EMPTY_STRING;

    const diffMs = this.diffMs(from, to);
    return diffMs < 1000 ? "teraz" : `${this._humanizer.humanize(diffMs)} temu`;
  }

  duration(from: Date, to: Date, plain = false) {
    if (from == null || to == null)
      return EMPTY_STRING;

    const diffMs = this.diffMs(from, to);

    if (plain)
      return diffMs + " ms";

    return diffMs ? this._humanizer.humanize(diffMs) : EMPTY_STRING;
  }

  format(date: Date, formatStr = DEFAULT_DATE_FORMAT) {
    if (!date)
      return EMPTY_STRING;

    if (typeof date === "string") date = new Date(date);

    return DateFormatLib.default(date, formatStr);
  }

  formatMsToMin(ms: number) {
    return ms / (60 * 1000);
  }

  private diffMs(from: Date, to: Date) {
    if (from == null || to == null)
      return null;

    if (typeof from === "string")
      from = new Date(from);

    if (typeof to === "string")
      to = new Date(to);

    return to.getTime() - from.getTime();
  }
}

export const dateFormatter = new DateFormatter(durationHumanizer);
