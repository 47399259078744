export class RelativeURL {
    private _searchParams = new URLSearchParams();

    constructor(private _path: string) { }

    get searchParams() {
        return this._searchParams;
    }

    toString() {
        let finalUrl = this._path;

        if (Array.from(this._searchParams.keys()).length > 0)
            finalUrl += "?" + this._searchParams.toString();

        return finalUrl;
    }

    applySearchParams(params: { [key: string]: string }) {
        Object.entries(params).forEach(e => {
            this.searchParams.set(e[0], e[1])
        });
        return this;
    }
}