import React, { FunctionComponent } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  plugins,
} from 'chart.js';
import { NoData } from "../../components/NoData";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export type TLineChartProps = {
  config: {
    x: {
      data: any[];
    };
    y: {
      data: any[];
      label?: string;
    };
  };
  options?: {
    showLegend?: boolean
  }
};

export const LineChart: FunctionComponent<TLineChartProps> = ({ config, options }) => {
  const hasData = config.y.data.filter(e => e != null).length > 1;
  return (
    <>
      {hasData ? (
        <Line
          data={{
            labels: config.x.data,
            datasets: [
              {
                data: config.y.data,
                label: config.y.label,
                fill: false,
                pointRadius: 0,
                borderColor: "#006699"
              },
            ],
          }}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: options?.showLegend ?? true
              }
            }
          }}
        />
      ) : (
        <NoData />
      )}
    </>
  );
};
