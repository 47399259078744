import React, { FunctionComponent, useCallback, useContext } from "react";
import { useFeed } from "../hooks/useFeed";
import { useCache } from "../hooks/useCache";
import { TestTypeContext } from "../context/TestTypeContext";
import { IFeatureTest } from "../@shared/types";

export type TTestsDataHOCProps = Partial<{
  testsByType: IFeatureTest[];
  tests: IFeatureTest[];
  isFetchingTests: boolean;
  fetchTestsByType: () => void;
}>;

export default function withTestsData<T>(WrappedComponent: React.ComponentType<T>) {
  const FetchTestDataWrapper: FunctionComponent<T & TTestsDataHOCProps> = ({ ...props }) => {
    const testType = useContext(TestTypeContext);
    const feed = useFeed<IFeatureTest>(`${testType}/tests`, [testType], () => testType != null);
    const cache = useCache();

    const reloadAll = useCallback(() => {
      feed.reload();
      cache.tests.reload();
    }, [feed]);

    return (
      <WrappedComponent
        {...props}
        testsByType={feed.response}
        tests={cache.tests.data}
        isFetchingTests={feed.pending}
        fetchTestsByType={() => reloadAll()}
      />
    );
  };

  return FetchTestDataWrapper;
};