import React, { useEffect, useState, FunctionComponent } from "react";
import AppToast, { TAppToastProps } from "../Toast";
import { Toast } from ".";

const MAX_TOASTS = 3;

export const ToastRoot: FunctionComponent<{}> = () => {
  const [toasts, setToasts] = useState<TAppToastProps[]>([]);

  useEffect(() => {
    const onShowFn = ({ message, type, id }) => setToasts(toasts => [
      ...toasts,
      {
        message,
        type,
        unmountFn: id => setToasts(toasts => toasts.filter(e => e.id !== id)),
        id,
      }
    ]);

    Toast.on("show-toast", onShowFn);

    return () => {
      Toast.off("show-toast", onShowFn);
    }
  }, []);

  return (
    toasts.length > 0 && (
      <div className="toasts-container">
        {toasts.slice(-MAX_TOASTS).map(props => (
          <AppToast key={props.id} {...props} />
        ))}
      </div>
    )
  );
};
