import React, { FunctionComponent } from "react";
import { Formik } from "formik";
import { Label, Input, Form, FormGroup, FormFeedback, Button } from "reactstrap";

import withEntityEdit, { TEditEntityHOCProps } from "../../hocs/withEntityEdit";
import { isValidUrl } from "../../utils/Utils";
import { EntityInfo } from "../../components/EntityInfo";
import { IUrl } from "../../@shared/types";

export type TEditUrlProps<T> = {
  entity: T;
  onSubmitFn: (entity: T) => void;
} & TEditEntityHOCProps;

const EditUrl: FunctionComponent<TEditUrlProps<IUrl>> = ({
  entity,
  onSubmitFn,
  updateEntities,
}) => {
  return (
    <Formik
      initialValues={entity}
      validate={values => {
        const errors: Partial<IUrl> = {},
          { url } = values;

        if (!url)
          errors.url = "Adres URL jest wymagany.";

        if (!isValidUrl(url))
          errors.url = "Wpisany tekst nie wygląda jak adres URL.";

        if (url.endsWith("/"))
          errors.url = "Nieprawidłowy znak na końcu.";

        if (!url.startsWith("http"))
          errors.url = "Brakujący protokół HTTP na początku adresu.";

        return errors;
      }}
      onSubmit={async (url: IUrl) => {
        const [updatedUrl] = await updateEntities([url]);
        onSubmitFn(updatedUrl);
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur, isSubmitting, handleSubmit }) => (
        <Form>
          <FormGroup>
            <Label for="url">Adres URL strony</Label>
            <Input
              type="text"
              name="url"
              id="url"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.url}
              invalid={errors.url !== undefined}
              placeholder="http://adres.pl"
            ></Input>
            <FormFeedback>{errors.url && touched.url && errors.url}</FormFeedback>
          </FormGroup>

          <EntityInfo entity={entity} />

          <div className="box box-h box-end">
            <Button
              color="primary"
              onClick={() => handleSubmit()}
              disabled={Object.keys(errors).length > 0}
            >
              Zapisz
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default withEntityEdit(EditUrl, "urls");
