import { faBoxOpen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FunctionComponent } from "react";

export const NoData: FunctionComponent<{}> = () => {
  return (
    <div className="box box-v box-fill box-center box-middle p-3" style={{ opacity: 0.33 }}>
      <FontAwesomeIcon icon={faBoxOpen} size="2x" className="mb-2" />
      <h5>Brak danych</h5>
    </div>
  );
};
