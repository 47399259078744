export interface IAppConfig {
  apiUrl: string;
}

export class AppConfig {
  private static _appConfig: IAppConfig = null;

  static get instance() {
    return this._appConfig;
  }

  static set(appConfig: IAppConfig) {
    this._appConfig = appConfig;
    console.log("App config set.");
  }
}