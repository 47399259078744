import React, { FunctionComponent } from "react";
import { updateEntities, removeEntities } from "../actions/entity";
import { TCacheContext } from "../context/CacheContext";
import { useCache } from "../hooks/useCache";
import { IEntity } from "../@shared/types";

export type TEditEntityHOCProps = Partial<{
  updateEntities: <T extends IEntity>(e: T[]) => Promise<T[]>;
  removeEntities: <T extends IEntity>(e: T[]) => Promise<number>;
}>;

export default function withEntityEdit<T>(WrappedComponent: React.ComponentType<T>, cacheKey?: keyof TCacheContext) {
  const EntityEditWrapper: FunctionComponent<T & TEditEntityHOCProps> = ({ ...props }) => {
    const cache = useCache();

    return (
      <WrappedComponent
        {...props}
        updateEntities={async (e: IEntity[]) => {
          const updatedEntities = await updateEntities(e);

          if (cacheKey)
            cache[cacheKey].reload();

          return updatedEntities;
        }}
        removeEntities={async (e: IEntity[]) => {
          const deletedCount = await removeEntities(e);

          if (cacheKey)
            cache[cacheKey].reload();

          return deletedCount;
        }}
      />
    );
  };

  return EntityEditWrapper;
};
