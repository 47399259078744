import React, { FunctionComponent, useContext } from "react";

import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { FeatureType, IFeatureTest, TSslLatestState } from "../../../@shared/types";
import { SessionContext } from "../../../context/SessionContext";
import { TestTypeContext } from "../../../context/TestTypeContext";
import withEditTest, { TEditEntityHOCProps } from "../../../hocs/withEntityEdit";
import withTestsData from "../../../hocs/withTestsData";
import { useCache } from "../../../hooks/useCache";
import { usePagedFeed } from "../../../hooks/useFeed";
import DefaultTemplate from "../../../templates/Default";
import { dateFormatter } from "../../../utils/DateFormatter";
import DataTable from "../../DataTable/DataTable";
import EditTest from "./EditTest";
import { Modal } from "../../@UI/Services";
import { autoSync } from "../../../actions/test";
import { Entity } from "../../../utils/Entity";
import { Intervals } from "../../../Constants";

const SslTestList: FunctionComponent<TEditEntityHOCProps> = ({ updateEntities, removeEntities }) => {
  const testType = useContext(TestTypeContext);
  const feed = usePagedFeed(`${testType}/tests`, [testType], () => testType != null);
  const session = useContext(SessionContext);
  const cache = useCache();

  return (
    <DefaultTemplate title="Konfiguracja testów Certyfikatów SSL" faIcon={Icons.faLock}>
      <DataTable
        feed={feed}
        searchBy={"url"}
        columnConfig={[
          {
            key: "url_id",
            type: "text",
            title: "Adres URL",
            renderFn: (e: IFeatureTest) => {
              const url = cache.urls.item(e.url_id);
              return <a
                href={url?.url}
                title="Zobacz stronę"
                target="_blank"
                rel="noopener noreferrer"
              >
                {url?.url}
              </a>
            },
          },
          {
            title: "Częstotliwość sprawdzania",
            renderFn: (e: IFeatureTest) => `${dateFormatter.formatMsToMin(e.interval_ms)} min`,
          }
        ]}
        rowKeyFn={e => e.id}
        options={{ selectableRows: true }}
        rowButtons={[
          {
            color: "secondary",
            faIcon: Icons.faCogs,
            title: "Konfiguruj",
            onClickFn: (e: IFeatureTest) => {
              Modal.open(<EditTest onSubmitFn={() => {
                Modal.closeTop();
                feed.reload();
              }} entity={e}></EditTest>, {
                title: "Konfiguracja testu",
              });
            },
            tooltip: "Edytuj konfigurację",
          },
          {
            color: "danger",
            faIcon: Icons.faTrash,
            onClickFn: (e: TSslLatestState) => {
              const url = cache.urls.item(e.url_id);

              Modal.confirm("Usuwanie testu", `Czy usunąć test dla adresu "${url?.url}"?`, async () => {
                const test = cache.tests.item(e.id);
                removeEntities([test]).then(() => feed.reload());
              });
            },
            title: "Usuń",
            tooltip: "Usuń test",
          },
        ]}
        topComponents={[
          <Button
            color="primary"
            outline
            onClick={() => {
              Modal.confirm(
                "Automatyczna synchronizacja adresów",
                "Adresy wspierające protokół HTTPS zostaną dodane, a niewspierające zostaną usunięte z monitora certyfikatów SSL. Czy chcesz kontynuować?",
                async () => {
                  await autoSync(FeatureType.Ssl);
                  feed.reload();
                  cache.tests.reload();
                });
            }}
            key="autoTestCreate"
          >
            <FontAwesomeIcon icon={Icons.faSync} /> <span className="mob-hidden">Synchronizacja adresów</span>
          </Button>,
          <Button
            color="primary"
            onClick={() => {
              Modal.open(
                <EditTest
                  entity={Entity.create<IFeatureTest>("FeatureTest", {
                    user_id: session.user.id,
                    interval_ms: Intervals["60_MIN"],
                    type: FeatureType.Ssl,
                    properties: {}
                  })}
                  onSubmitFn={() => {
                    feed.reload();
                    Modal.closeTop();
                  }}
                ></EditTest>,
                {
                  title: "Dodawanie nowego testu",
                },
              );
            }}
            key="addTestBtn"
          >
            <FontAwesomeIcon icon={Icons.faPlus} /> <span className="mob-hidden">Dodaj stronę</span>
          </Button>,
        ]}
        batchOperations={{
          delete: {
            name: "Usuń",
            onSubmitFn: (rows: TSslLatestState[]) => {
              Modal.confirm(
                "Usuwanie wielu testów",
                `Wybrane testy (${rows.length}) oraz powiązane z nimi dane zostaną usunięte. Czy kontynuować?`,
                () => removeEntities(cache.tests.items(rows.map(e => e.id))).then(() => feed.reload()),
              );
            },
          },
        }}
      />
    </DefaultTemplate >
  );
};

export default withEditTest(withTestsData(SslTestList), "tests");
