import React, { FunctionComponent } from "react";
import { DEFAULT_PERIOD, Periods } from "../../../../Constants";
import { Input } from "reactstrap";

const TimeRangeSelector: FunctionComponent<{
    onSelect: (v: number) => void;
}> = ({ onSelect }) => {
    return (
        <div className="box box-h box-spaced box-middle">
            <div className="text-nowrap">Okres czasu:</div>
            <Input
                type="select"
                name="period"
                id="period"
                className="w-auto"
                onChange={e => onSelect(Number(e.target.value))}
                defaultValue={DEFAULT_PERIOD}
            >
                <option value={Periods.ONE_DAY}>1 dzień</option>
                <option value={Periods.ONE_WEEK}>7 dni</option>
                <option value={Periods.ONE_MONTH}>30 dni</option>
                <option value={Periods.THREE_MONTHS}>90 dni</option>
            </Input>
        </div>
    )
}

export default TimeRangeSelector;