import React, { FunctionComponent, useContext, useMemo } from "react";
import { faGlobe, faHeartbeat, faLock, faTasks, faUsers } from "@fortawesome/free-solid-svg-icons";

import { routes } from "../routes";
import Logo from "./@UI/Logo";
import { SideNavItem, TSideItem } from "./SideNavItem";
import { SessionContext } from "../context/SessionContext";
import { Version } from "./Version";

const Sidebar: FunctionComponent<{ isOpen: boolean }> = ({ isOpen }) => {
  const session = useContext(SessionContext);
  const items = useMemo<TSideItem[]>(() => [
    {
      title: "Adresy URL",
      faIcon: faGlobe,
      link: routes.monitorStatus,
    },
    {
      title: "Dostępność",
      faIcon: faHeartbeat,
      link: routes.availability,
      subitems: [
        {
          title: "Kokpit",
          link: routes.availability,
        },
        {
          title: "Konfiguracja testów",
          link: routes.availabilityTests,
        },
      ],
    },
    {
      title: "Certyfikaty SSL",
      faIcon: faLock,
      link: routes.ssl,
      subitems: [
        {
          title: "Kokpit",
          link: routes.ssl,
        },
        {
          title: "Konfiguracja testów",
          link: routes.sslTests,
        },
      ],
    },
    {
      title: "Historia wykonania zadań",
      faIcon: faTasks,
      link: routes.taskHistory,
      visibleFn: () => session.user.is_admin
    },
    {
      title: "Użytkownicy",
      faIcon: faUsers,
      link: routes.users,
      visibleFn: () => session.user.is_admin
    },
  ], []);

  return (
    <div className={`sidebar ${isOpen ? "sidebar-open" : ""} box box-v box-fill`}>
      <div className="sidebar-logo"><Logo /></div>

      <div className="nav box box-v box-fill scroll flex-nowrap">
        {items
          .filter(e => typeof e.visibleFn === "function" ? e.visibleFn() : true)
          .map((e, i) => <SideNavItem item={e} key={i} />)}
      </div>

      <small className="p-2 text-center bg-dark">
        <Version />
      </small>
    </div>
  );
};

export default Sidebar;
