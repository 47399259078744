import { flip, shift, computePosition } from "@floating-ui/dom";
import React, { useEffect, useState, FunctionComponent, ReactNode } from "react";
import { Sticky } from ".";

type TStickyRootProps = {
}

export const StickyRoot: FunctionComponent<TStickyRootProps> = () => {
    const [components, setComponents] = useState<{ hostEl: HTMLElement, bodyComp: ReactNode }[]>([]);

    useEffect(() => {
        const
            onOpenFn = ({ hostEl, bodyComp }) => setComponents(components => [
                ...components,
                { hostEl, bodyComp },
            ]),
            onCloseFn = () => setComponents(components => [...components.slice(0, -1)]);

        Sticky.on("open-sticky", onOpenFn);
        Sticky.on("close-top-sticky", onCloseFn);

        return () => {
            Sticky.off("open-sticky", onOpenFn);
            Sticky.off("close-top-sticky", onCloseFn);
        }
    }, []);

    const setPosition = async (hostEl: HTMLElement, stickyEl: HTMLElement) => {
        if (!stickyEl)
            return;

        const pos = await computePosition(hostEl, stickyEl, {
            placement: "bottom-start",
            middleware: [shift({ padding: 20 })]
        });
        stickyEl.style.left = `${pos.x}px`;
        stickyEl.style.top = `${pos.y}px`;
    }

    return (
        components.length > 0 && (
            <div className="sticky-container" onClick={() => Sticky.closeTop()}>
                {components.map(({ hostEl, bodyComp }, i) => {
                    return (
                        <div
                            ref={ref => setPosition(hostEl, ref)}
                            className="sticky box box-v box-fill"
                            key={`sticky-${i}`}
                            onClick={ev => ev.stopPropagation()}>
                            {bodyComp}
                        </div>
                    );
                })}
            </div>
        )
    );
};
