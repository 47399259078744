import React, { FunctionComponent } from "react";
import { IUser } from "../@shared/types";
import { dateFormatter } from "../utils/DateFormatter";
import { useFetch } from "../hooks/useFetch";
import Loader from "./Loader";

export const UserDetails: FunctionComponent<{ user: IUser }> = ({ user }) => {
    const urlCountFetch = useFetch(`user/url-count/${user.id}`);
    const testCountFetch = useFetch(`user/test-count/${user.id}`);

    return (
        <table className="table">
            <tbody>
                <tr>
                    <td>Adres email</td>
                    <td>{user.email}</td>
                </tr>
                <tr>
                    <td>Utworzono</td>
                    <td>{dateFormatter.format(user.created_on)}</td>
                </tr>
                <tr>
                    <td>Liczba adresów URL</td>
                    <td>
                        <Loader pending={urlCountFetch.pending} align="left">
                            {urlCountFetch.response}
                        </Loader>
                    </td>
                </tr>
                <tr>
                    <td>Liczba testów</td>
                    <td>
                        <Loader pending={testCountFetch.pending} align="left">
                            {testCountFetch.response}
                        </Loader>
                    </td>
                </tr>
                <tr>
                    <td>Data ostatniego logowania</td>
                    <td>{dateFormatter.format(user.last_login_on)}</td>
                </tr>
                <tr>
                    <td>Data ostatniego błędnego logowania</td>
                    <td>{dateFormatter.format(user.last_failed_login_on)}</td>
                </tr>
            </tbody>
        </table>
    );
};