export enum Interval {
    "1_MIN" = 1 * 60 * 1000,
    "5_MIN" = 5 * 60 * 1000,
    "15_MIN" = 15 * 60 * 1000,
    "30_MIN" = 30 * 60 * 1000,
    "60_MIN" = 60 * 60 * 1000,
    "24_H" = 24 * 60 * 60 * 1000
};

export enum State {
    UP = 1,
    DOWN = 0
}

export enum TaskStatus {
    FAILURE = 0,
    SUCCESS = 1,
    WARNING = 2,
    QUEUED = 3,
    RUNNING = 4
}

export enum FeatureType {
    Availability = "availability",
    Ssl = "ssl"
}

export enum FeatureEventType {
    Recovered = 100,
    BreakdownDetected = 200
}

export enum SslEventType {
    ExpireSoon = 1000
}