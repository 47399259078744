import { Toast } from "../components/@UI/Services";
import { FeatureType } from "../@shared/types";
import { HttpClient } from "../utils/HttpClient";

export const autoSync = (testType: FeatureType) => {
  if (testType !== FeatureType.Ssl)
    throw new Error("Not supported test type.");

  const requestId = new Date().getTime();

  Toast.show("Synchronizowanie adresów - proszę czekać.");
  return HttpClient.instance.post(requestId, `${testType}/tests/autosync`, {})
    .then(({ createdTestsCounter, deletedTestsCounter }) => {
      if (createdTestsCounter === 0 && deletedTestsCounter === 0)
        Toast.show("Nie znaleziono adresów możliwych do zsynchronizowania.");
      else {
        if (createdTestsCounter > 0)
          Toast.show(`Liczba dodanych adresów do monitora certyfikatów SSL: ${createdTestsCounter}.`);

        if (deletedTestsCounter > 0)
          Toast.show(`Liczba usuniętych adresów z monitora certyfikatów SSL: ${deletedTestsCounter}.`);
      }
    });
};
