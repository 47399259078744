import React, { FunctionComponent, useContext } from "react";
import { Formik } from "formik";
import {
  CustomInput,
  Label,
  Input,
  Form,
  FormGroup,
  FormText,
  Button,
} from "reactstrap";

import { Intervals } from "../../../Constants";
import withEntityEdit, { TEditEntityHOCProps } from "../../../hocs/withEntityEdit";
import Selector from "../../../components/@UI/Selector/Selector";
import { useCache } from "../../../hooks/useCache";
import { TestTypeContext } from "../../../context/TestTypeContext";
import { UrlHelper } from "../../../components/Url/Helper";
import { EntityInfo } from "../../../components/EntityInfo";
import { IAvailabilityTestProperties, IFeatureTest } from "../../../@shared/types";

export type TEditTestProps<T> = {
  entity: T;
  onSubmitFn: () => void;
} & TEditEntityHOCProps;

const EditTest: FunctionComponent<TEditTestProps<IFeatureTest<IAvailabilityTestProperties>>> = ({
  entity,
  onSubmitFn,
  updateEntities
}) => {
  const cache = useCache();
  const testType = useContext(TestTypeContext);

  return (
    <Formik
      initialValues={entity}
      validate={e => {
        const errors: { [key in keyof IFeatureTest<IAvailabilityTestProperties>]?: string } = {};

        if (e.url_id == null)
          errors.url_id = "URL jest wymagany.";

        return errors;
      }}
      onSubmit={async e => {
        await updateEntities([e]);
        onSubmitFn();
      }}
    >
      {({ values, errors, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
        <Form>
          <FormGroup>
            <Label>Adres URL strony</Label>
            <Selector
              items={UrlHelper.getNotUsed(testType, cache.tests.data, cache.urls.data)}
              selected={values.url_id ? [cache.urls.item(values.url_id)] : []}
              displayFn={e => e.url}
              keyFn={e => e.id}
              onSelect={e => setFieldValue("url_id", e?.id)}
              single
              disabled={entity.id != null}
            />
            {errors.url_id && <small className="text-danger">{errors.url_id}</small>}
          </FormGroup>

          <FormGroup>
            <Label>Częstotliwość sprawdzania</Label>
            <Input
              type="select"
              name="interval_ms"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.interval_ms}
            >
              <option value={Intervals["5_MIN"]}>5 min</option>
              <option value={Intervals["15_MIN"]}>15 min</option>
              <option value={Intervals["30_MIN"]}>30 min</option>
              <option value={Intervals["60_MIN"]}>60 min</option>
            </Input>
          </FormGroup>

          <FormGroup>
            <Label>Czas tolerancji okresu niedostępności</Label>
            <CustomInput
              type="range"
              name="properties.breakdownTimeTolerance"
              id="breakdownTimeTolerance"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.properties.breakdownTimeTolerance}
              min={0}
              max={60 * 60 * 1000}
              step={15 * 60 * 1000}
            />
            <div className="box box-h box-justified">
              <div>Żaden</div>
              <div>15 min</div>
              <div>30 min</div>
              <div>45 min</div>
              <div>60 min</div>
            </div>
            <FormText>
              Okresy niedostępności nie będą raportowane, jeżeli czas ich trwania będzie krótszy niż czas tolerancji.
            </FormText>
          </FormGroup>

          <FormGroup>
            <Label>Dodatkowi odbiorcy powiadomień</Label>
            <div className="box box-v box-spaced">
              {[1, 2, 3].map((e, i) =>
                <Input
                  key={`email-${i}`}
                  type="text"
                  name={`properties.notifyUserEmails[${i}]`}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.properties?.notifyUserEmails?.[i]}
                />
              )}
            </div>
            <FormText>
              Dodatkowe adresy email, na które będą wysyłane powiadomienia.
            </FormText>
          </FormGroup>

          <FormGroup className="box box-h">
            <CustomInput
              type="switch"
              name="properties.notifyDisabled"
              id="properties.notifyDisabled"
              checked={!values.properties?.notifyDisabled}
              onChange={() => setFieldValue("properties.notifyDisabled", !values.properties?.notifyDisabled)}
            />
            <Label>Włącz powiadomienia</Label>
          </FormGroup>

          <EntityInfo entity={entity} />

          <div className="box box-h box-end">
            <Button
              color="primary"
              onClick={() => handleSubmit()}
              disabled={Object.keys(errors).length > 0}
            >
              Zapisz
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default withEntityEdit(EditTest, "tests");
