import React, { FunctionComponent, useContext } from "react";

import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeartbeat,
  faPlus,
  faCogs,
  faTrash,
  faChartLine,
} from "@fortawesome/free-solid-svg-icons";

import DataTable from "../../../components/DataTable/DataTable";
import DefaultTemplate from "../../../templates/Default";
import EditTest from "../../../components/feature/availability/EditTest";
import withTestsData from "../../../hocs/withTestsData";
import withEditTest, { TEditEntityHOCProps } from "../../../hocs/withEntityEdit";
import { Modal } from "../../@UI/Services";
import { dateFormatter } from "../../../utils/DateFormatter";
import { TestTypeContext } from "../../../context/TestTypeContext";
import { usePagedFeed } from "../../../hooks/useFeed";
import EditBatchTest from "./EditBatchTest";
import { SessionContext } from "../../../context/SessionContext";
import { AvailabilityTest } from "../../../utils/Entity";
import Statistics from "./stats/Statistics";
import { useCache } from "../../../hooks/useCache";
import { IAvailabilityTestProperties, IFeatureTest } from "../../../@shared/types";

const AvailabilityTestList: FunctionComponent<TEditEntityHOCProps> = ({ updateEntities, removeEntities }) => {
  const testType = useContext(TestTypeContext);
  const feed = usePagedFeed(`${testType}/tests`, [testType], () => testType != null);
  const session = useContext(SessionContext);
  const cache = useCache();

  return (
    <DefaultTemplate title="Konfiguracja testów dostępności" faIcon={faHeartbeat}>
      <DataTable
        feed={feed}
        searchBy={"url"}
        columnConfig={[
          {
            key: "url_id",
            type: "text",
            title: "Adres URL",
            renderFn: (e: IFeatureTest<IAvailabilityTestProperties>) => {
              const url = cache.urls.item(e.url_id);
              return <a
                href={url?.url}
                title="Zobacz stronę"
                target="_blank"
                rel="noopener noreferrer"
              >
                {url?.url}
              </a>
            },
          },
          {
            title: "Częstotliwość sprawdzania",
            renderFn: (e: IFeatureTest<IAvailabilityTestProperties>) => `${dateFormatter.formatMsToMin(e.interval_ms)} min`,
          },
          {
            title: "Czas tolerancji okresu niedostępności",
            renderFn: (e: IFeatureTest<IAvailabilityTestProperties>) =>
              `${dateFormatter.formatMsToMin(e.properties.breakdownTimeTolerance)} min`,
          },
        ]}
        rowKeyFn={e => e.id}
        options={{ selectableRows: true }}
        rowButtons={[
          {
            color: "secondary",
            faIcon: faCogs,
            title: "Konfiguruj",
            onClickFn: (e: IFeatureTest<IAvailabilityTestProperties>) => {
              Modal.open(<EditTest onSubmitFn={() => {
                Modal.closeTop();
                feed.reload();
              }} entity={e}></EditTest>, {
                title: "Konfiguracja testu",
              });
            },
            tooltip: "Edytuj konfigurację",
          },
          {
            color: "danger",
            faIcon: faTrash,
            onClickFn: (e: IFeatureTest<IAvailabilityTestProperties>) => {
              Modal.confirm(
                "Usuwanie testu",
                `Czy usunąć test dla adresu "${cache.urls.item(e.url_id)?.url}"?`,
                () => removeEntities([e]).then(() => feed.reload()),
              );
            },
            title: "Usuń",
            tooltip: "Usuń test",
          },
        ]}
        topComponents={[
          <Button
            color="primary"
            onClick={() =>
              Modal.open(
                <EditTest
                  entity={AvailabilityTest.create(session.user.id)}
                  onSubmitFn={() => {
                    Modal.closeTop();
                    feed.reload();
                  }}
                ></EditTest>,
                {
                  title: "Dodawanie nowego testu",
                },
              )
            }
            key="addTestBtn"
          >
            <FontAwesomeIcon icon={faPlus} /> <span className="mob-hidden">Dodaj stronę</span>
          </Button >,
        ]}
        batchOperations={{
          delete: {
            name: "Usuń",
            onSubmitFn: (rows: IFeatureTest<IAvailabilityTestProperties>[]) => {
              Modal.confirm(
                "Usuwanie wielu testów",
                `Wybrane testy (${rows.length}) oraz powiązane z nimi dane zostaną usunięte. Czy kontynuować?`,
                () => removeEntities(rows).then(() => feed.reload()),
              );
            },
          },
          edit: {
            name: "Edytuj",
            onSubmitFn: rows => Modal.open(<EditBatchTest tests={rows} onSubmitFn={() => {
              Modal.closeTop();
              feed.reload();
            }} />, {
              title: "Konfiguracja wielu testów",
            })
          }
        }}
      />
    </DefaultTemplate >
  );
};

export default withEditTest(withTestsData(AvailabilityTestList), "tests");
