import React, { FunctionComponent } from "react";
import { Input } from "reactstrap";
import { EMPTY_STRING } from "../../Constants";
import { TaskStatus } from "../../@shared/types";
import Selector from "../@UI/Selector/Selector";
import { TColumnType } from "./DataTable";

type TFilterItemProps = {
    title: string,
    type: TColumnType,
    onChangeFn: (v: any) => void,
    value: any
}

export const FilterItem: FunctionComponent<TFilterItemProps> = ({ title, type, onChangeFn, value }) => {
    return (
        <label>
            <div>{title}</div>
            {type === "text" && <Input type="text" value={value} onChange={(e) => onChangeFn(e.target.value)} />}
            {type === "number" && <Input type="number" value={value} onChange={(e) => onChangeFn(e.target.value)} />}
            {type === "date" && <Input type="date" value={value} onChange={(e) => onChangeFn(e.target.value)} />}
            {type === "task-status" && <Selector
                single
                selected={value}
                items={Object.keys(TaskStatus).filter(e => Number.isInteger(Number(e))).map(e => Number(e))}
                displayFn={e => {
                    switch (Number(e)) {
                        case TaskStatus.QUEUED:
                            return "Oczekujący";

                        case TaskStatus.RUNNING:
                            return "Uruchomiony";

                        case TaskStatus.FAILURE:
                            return "Niepowodzenie";

                        case TaskStatus.SUCCESS:
                            return "Zakończony";

                        case TaskStatus.WARNING:
                            return "Ostrzeżenie";

                        default:
                            return EMPTY_STRING;
                    }
                }}
                keyFn={e => e}
                onSelect={e => onChangeFn(e)}
            />}
        </label>
    );
}