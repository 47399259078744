import React, { FunctionComponent, useState } from "react";

import * as Icons from "@fortawesome/free-solid-svg-icons";
import DataTable from "../../../components/DataTable/DataTable";
import DefaultTemplate from "../../../templates/Default";
import CardCounter, { TCardCounterType } from "../../CardCounter";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import AppBadge from "../../../components/Badge";
import { State, TAvailabilityLatestState } from "../../../@shared/types";
import { dateFormatter } from "../../../utils/DateFormatter";
import Statistics from "./stats/Statistics";
import { Modal } from "../../@UI/Services";
import Loader from "../../Loader";
import { usePagedFeed } from "../../../hooks/useFeed";
import { queueTask } from "../../../actions/task";
import { useFetch } from "../../../hooks/useFetch";

export type TCounterData = {
  type: TCardCounterType;
  counter: number;
  text: string;
  icon: IconProp;
};

const AvailabilityCockpit: FunctionComponent = () => {
  const feed = usePagedFeed<TAvailabilityLatestState>("/availability/states/latest");
  const countersFeed = useFetch<{
    all: number,
    ok: number,
    nok: number
  }>("/availability/states/latest/counters");
  const [triggeredTestIds, setTriggeredTestIds] = useState<number[]>([]);
  const stateCounterData: TCounterData[] = [
    {
      type: "primary",
      counter: countersFeed.response?.all,
      text: "Monitorowanych stron",
      icon: Icons.faEye,
    },
    {
      type: "success",
      counter: countersFeed.response?.ok,
      text: "Działających stron",
      icon: Icons.faCheck,
    },
    {
      type: "danger",
      counter: countersFeed.response?.nok,
      text: "Nie działających stron",
      icon: Icons.faTimes,
    },
  ]

  return (
    <DefaultTemplate title="Dostępność" faIcon={Icons.faHeartbeat}>
      <Loader pending={countersFeed.pending}>
        <div className="counter-wrapper mob-hidden">
          {stateCounterData.map((data, i) => (
            <CardCounter
              key={i}
              type={data.type}
              value={data.counter}
              text={data.text}
              faIcon={data.icon}
            />
          ))}
        </div>
      </Loader>

      <DataTable
        feed={feed}
        searchBy={"url"}
        columnConfig={[
          {
            key: "url",
            type: "text",
            title: "Adres URL",
            renderFn: (e: TAvailabilityLatestState) => {
              return (
                <a href={e?.url} title="Zobacz stronę" target="_blank" rel="noopener noreferrer">
                  {e?.url}
                </a>
              );
            },
          },
          {
            key: "state",
            type: "number",
            title: "Aktualny Stan",
            renderFn: (e: TAvailabilityLatestState) => {
              switch (e.state) {
                case State.UP:
                  return <AppBadge color="success" faIcon={Icons.faCheckCircle} text="Działa" />;

                case State.DOWN:
                  return <AppBadge color="danger" faIcon={Icons.faTimesCircle} text="Nie działa" />

                default:
                  return <AppBadge color="secondary" faIcon={Icons.faQuestionCircle} text="Nieznany" />;
              }
            },
          },
          {
            key: "state_details",
            title: "",
            renderFn: (e: TAvailabilityLatestState) => e.breakdown && e.state === State.DOWN && (
              <div className="box box-v">
                <small title={dateFormatter.format(e.breakdown.detectedOn)}>
                  Czas wykrycia: <b>{dateFormatter.elapse(e.breakdown.detectedOn, new Date())}</b>
                </small>

                {e.breakdown.httpCode && (
                  <small>
                    Kod odpowiedzi HTTP: <b>{e.breakdown.httpCode}</b>
                  </small>
                )}

                {e.breakdown.errorMessage && (
                  <small>
                    Przyczyna: <b>{e.breakdown.errorMessage}</b>
                  </small>
                )}
              </div>
            )
          },
          {
            title: "Ostatnie sprawdzenie",
            renderFn: (e: TAvailabilityLatestState) => e.created_on ? dateFormatter.elapse(e.created_on, new Date()) : "Oczekiwanie..."
          },
        ]}
        rowKeyFn={e => e.id}
        rowButtons={[
          {
            color: "primary",
            faIcon: Icons.faChartLine,
            onClickFn: (e: TAvailabilityLatestState) => {
              Modal.open(<Statistics testId={e.test_id} url={e.url} />, {
                title: "Statystyki testów dostępności",
                minWidth: "90vw"
              });
            },
            title: "Statystyki",
            tooltip: "Zobacz statystyki",
          },
          {
            color: "success",
            faIcon: Icons.faSyncAlt,
            onClickFn: async (e: TAvailabilityLatestState) => {
              setTriggeredTestIds(v => [...v, e.test_id]);
              await queueTask("FeatureTestExecutorTask", { testId: e.test_id })
                .finally(() => setTriggeredTestIds(v => v.filter(f => f !== e.test_id)));

              feed.reload();
              countersFeed.reload();
            },
            title: "Odśwież teraz",
            disabledFn: (e: TAvailabilityLatestState) => triggeredTestIds.includes(e.test_id)
          },
        ]}
      />
    </DefaultTemplate>
  );
};

export default AvailabilityCockpit;
