import { SessionContext } from "../context/SessionContext";
import React, { FunctionComponent, useContext } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { routes } from "../routes";

const PrivateRoute: FunctionComponent<RouteProps> = ({
  component: Component,
  path,
  ...restProps
}) => {
  const session = useContext(SessionContext);

  return (
    <Route
      {...restProps}
      path={path}
      render={props => {
        return session.user != null ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: routes.login, state: { from: props.location } }} />
        );
      }}
    />
  );
};

export default PrivateRoute;
