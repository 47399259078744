import React, { FunctionComponent, useEffect, useState } from "react";
import { Sticky } from "../Services";
import SelectorComponent from "./Component";
import SelectorItems from "./Items";

export type TSelectorProps<T> = {
  onSelect: (items: T[] | T) => void;
  items: T[];
  selected?: T[];
  single?: boolean;
  displayFn: (e: T) => string;
  keyFn: (e: T) => any;
  disabled?: boolean;
};

const Selector: FunctionComponent<TSelectorProps<any>> = ({
  onSelect,
  items,
  selected = [],
  single = false,
  displayFn,
  keyFn,
  disabled = false,
}) => {
  const [selectedItems, setSelectedItems] = useState<any[]>(Array.isArray(selected) ? selected : [selected]);
  const [itemsRef, setItemsRef] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    onSelect(single ? selectedItems[0] : selectedItems);
  }, [selectedItems]);

  useEffect(() => {
    const
      onCloseFn = () => setIsOpen(false),
      onOpenFn = () => setIsOpen(true);

    Sticky.on("close-top-sticky", onCloseFn);
    Sticky.on("open-sticky", onOpenFn);

    return () => {
      Sticky.off("close-top-sticky", onCloseFn);
      Sticky.off("open-sticky", onOpenFn);
    }
  }, []);

  return (
    <SelectorItems
      onRefInit={ref => setItemsRef(ref)}
      selected={selectedItems}
      onClick={() => {
        if (isOpen)
          return Sticky.closeTop();

        Sticky.open(itemsRef, <SelectorComponent
          items={items}
          selected={selectedItems}
          onSelect={items => {
            setSelectedItems(items);
            Sticky.closeTop();
          }}
          single={single}
          displayFn={displayFn}
          keyFn={keyFn}
        />);
      }}
      displayFn={displayFn}
      keyFn={keyFn}
      disabled={disabled}
    />
  );
};

export default Selector;
