import React, { FunctionComponent } from "react";
import { Formik, FormikConfig } from "formik";
import { ErrorService } from "../ErrorService";

const AppFormik: FunctionComponent<FormikConfig<any>> = ({ onSubmit, ...rest }) => {
    return (
        <Formik
            {...rest}
            onSubmit={async (...submitProps) => {
                try {
                    await onSubmit(...submitProps);
                } catch (err) {
                    ErrorService.process(err);
                    throw err;
                } finally {
                    submitProps[1].setSubmitting(false);
                }
            }}
        />
    )
}

export default AppFormik;