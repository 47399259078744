import React, { useState, useEffect, FunctionComponent } from "react";
import { Toast, ToastBody } from "reactstrap";

export type TAppToastProps = {
  key?: any;
  id: number;
  message: string;
  type?: string;
  closeTimeout?: number;
  unmountFn?: (id: number) => void;
};

const AppToast: FunctionComponent<TAppToastProps> = ({
  id,
  message,
  type = "info",
  closeTimeout = 8000,
  unmountFn,
}) => {
  const [open, setOpen] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setOpen(false);
      setTimeout(() => unmountFn && unmountFn(id), 500); // wait for transition end
    }, closeTimeout);
  }, [closeTimeout, unmountFn, id]);

  return (
    <Toast isOpen={open} className={`toast toast-${type}`}>
      <ToastBody>{message}</ToastBody>
    </Toast>
  );
};

export default AppToast;
