import React, { useEffect, useState, FunctionComponent, ReactNode } from "react";
import { Button } from "reactstrap";
import { Modal } from ".";
import BasicModal, { TBasicModalProps } from "../Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type TModal = { bodyComp: ReactNode; config: TBasicModalProps };

export const ModalRoot: FunctionComponent<{}> = () => {
  const [modals, setModals] = useState<TModal[]>([]);

  useEffect(() => {
    const
      onOpenFn = ({ bodyComp, config }) => setModals(modals => [...modals, { bodyComp, config }]),
      onOpenConfirmFn = ({ title, message, faIcon, onConfirmFn, onCancelFn }) => setModals(modals => [...modals, {
        config: { title },
        bodyComp: (
          <div className="box box-v box-spaced">
            <div className="box box-h box-spaced box-middle">
              {faIcon && <FontAwesomeIcon icon={faIcon} size="2x" />}
              <div>{message}</div>
            </div>
            <div className="box box-h box-spaced box-end">
              {typeof onConfirmFn === "function" && <Button
                color="primary"
                onClick={() => {
                  if (typeof onConfirmFn === "function")
                    onConfirmFn();

                  Modal.closeTop();
                }}
              >
                Potwierdź
              </Button>
              }
              <Button
                color="primary"
                outline
                onClick={() => {
                  if (typeof onCancelFn === "function")
                    onCancelFn();

                  Modal.closeTop();
                }}
              >
                Zamknij
              </Button>
            </div>
          </div>
        )
      }]),
      onCloseFn = () => setModals(modals => [...modals.slice(0, -1)]);

    Modal.on("open-modal", onOpenFn);
    Modal.on("open-confirm-modal", onOpenConfirmFn);
    Modal.on("close-top-modal", onCloseFn);

    return () => {
      Modal.off("open-modal", onOpenFn);
      Modal.off("open-confirm-modal", onOpenConfirmFn);
      Modal.off("close-top-modal", onCloseFn);
    }
  }, []);

  return (
    <>
      {modals.map(({ bodyComp, config }, i) => (
        <BasicModal key={i} index={i} {...config}>
          {bodyComp}
        </BasicModal>
      ))}
    </>
  );
};
