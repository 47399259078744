import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink as Link } from "react-router-dom";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

export type TSideItem = {
  title: string;
  link: string;
  faIcon?: IconProp;
  subitems?: TSideItem[];
  visibleFn?: () => boolean;
}

export const SideNavItem: FunctionComponent<{
  item: TSideItem,
  onChildItemEnter?: () => void
}> = ({ item, onChildItemEnter }) => {
  const linkRef = useRef(null);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    const linkIsActive = linkRef.current.classList.contains("nav-active") as boolean;
    setIsActive(linkIsActive);
  });

  const hasSubItems = item.subitems?.length > 0;
  const isChildItem = typeof onChildItemEnter === "function";
  if (isChildItem)
    onChildItemEnter();

  return (
    <div className={`nav-item-wrapper ${isActive ? "nav-item-wrapper-active" : ""} box box-v w-100`}>
      <Link
        to={item.link ?? "#"}
        activeClassName="nav-active"
        className={`nav-item ${isChildItem && "nav-sub-item"}`}
        ref={linkRef}
        isActive={match => match?.isExact || isActive}
      >
        <div className="box box-h box-middle box-justified">
          <div className="box box-h box-middle box-spaced">
            {item.faIcon && <FontAwesomeIcon icon={item.faIcon} />}
            <div>{item.title}</div>
          </div>

          {hasSubItems && <FontAwesomeIcon icon={isActive ? faChevronUp : faChevronDown} />}
        </div>
      </Link>

      {hasSubItems && (
        <div className={`nav box box-v ${isActive ? "" : "hidden"}`}>
          {item.subitems.map((e, i) => <SideNavItem item={e} key={"sub" + i} onChildItemEnter={() => {
            setIsActive(true);
          }} />)}
        </div>
      )}
    </div>
  );
};
