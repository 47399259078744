import { TBasicModalProps } from "../Modal";
import { ReactNode } from "react";
import { DocumentEventEmitter } from "../../../DocumentEventEmitter";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export class ModalService extends DocumentEventEmitter<"open-modal" | "open-confirm-modal" | "close-top-modal"> {
  open(bodyComp: ReactNode, config: TBasicModalProps) {
    return this.emit("open-modal", { bodyComp, config });
  }

  confirm(title: string, message: string, onConfirmFn?: () => void, onCancelFn?: () => void, faIcon?: IconProp) {
    return this.emit("open-confirm-modal", {
      title,
      message,
      faIcon,
      onConfirmFn,
      onCancelFn
    });
  }

  closeTop() {
    return this.emit("close-top-modal");
  }
};
