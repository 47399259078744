import React, { FunctionComponent, useState } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Modal as ModalService } from "./Services";

export type TBasicModalProps = {
  key?: any;
  title: string;
  onCloseFn?: (index: number) => void;
  minWidth?: string;
  minHeight?: string;
};

const BasicModal: FunctionComponent<TBasicModalProps & {
  index: number;
}> = ({ children, index, title, onCloseFn = () => ModalService.closeTop(), minWidth, minHeight }) => {
  const [isOpen, setIsOpen] = useState(true);
  const toggleFn = () => setIsOpen(!isOpen);

  return (
    <Modal centered isOpen={isOpen} toggle={() => toggleFn()} onClosed={() => onCloseFn(index)} style={{ minWidth, minHeight }}>
      <ModalHeader toggle={() => toggleFn()}>{title}</ModalHeader>
      <ModalBody>{children}</ModalBody>
    </Modal>
  );
};

export default BasicModal;
