import { useCallback, useEffect, useState } from "react"
import { TFetch, useFetch } from "./useFetch";
import { RelativeURL } from "../utils/RelativeUrl";

export type TFeed<T> = TFetch<T[]>;
export const useFeed = <T = any>(path: string, triggers = [], triggerFn?: () => boolean, initialValue?: any): TFeed<T> => {
    const fetch = useFetch<T[]>(path, triggers, triggerFn, initialValue);

    return {
        ...fetch,
        response: fetch.response ?? []
    };
}

export type TPagedFeed<T> = TFeed<T> & {
    search?: (text: string, by: string) => void,
    filter?: (v: { [key: string]: string }) => void,
    currentPageIdx: number,
    setCurrentPageIdx: (idx: number) => void,
    itemsPerPage: number,
    setItemsPerPage: (perPage: number) => void,
    itemsCount: number
};

type TCountFeedResp = number | [{ count: number }];

export const usePagedFeed = <T = any>(path: string, triggers = [], triggerFn?: () => boolean): TPagedFeed<T> => {
    const
        [currentPageIdx, setCurrentPageIdx] = useState(0),
        [itemsPerPage, setItemsPerPage] = useState(10),
        [search, setSearch] = useState<{ text: string, by: string }>(null),
        [filter, setFilter] = useState<{ [key: string]: string }>(null),
        commonParams: { [key: string]: string } = {};

    if (search?.text && search?.by) {
        commonParams["search"] = search.text;
        commonParams["searchBy"] = search.by;
    }

    if (filter && Object.keys(filter).length > 0)
        commonParams["filter"] = encodeURIComponent(JSON.stringify(filter));

    triggers.push(search?.text, currentPageIdx, itemsPerPage, filter);

    const
        feedUrl = new RelativeURL(path).applySearchParams({
            ...commonParams,
            page: currentPageIdx.toString(),
            limit: itemsPerPage.toString(),
        }),
        countUrl = new RelativeURL(path).applySearchParams({
            ...commonParams,
            count: "true"
        }),
        feed = useFeed<T>(feedUrl.toString(), triggers, triggerFn, []),
        countFetch = useFetch<TCountFeedResp>(countUrl.toString(), triggers, triggerFn);

    useEffect(() => {
        setCurrentPageIdx(0);
    }, [itemsPerPage]);

    const counter = Number(countFetch.response?.[0]?.["count"]);
    const itemsCount = Number.isInteger(counter) ? counter : 0;

    return {
        ...feed,
        currentPageIdx,
        setCurrentPageIdx,
        itemsPerPage,
        setItemsPerPage,
        itemsCount,
        search: useCallback((text, by) => {
            if (!text)
                setSearch(null);

            if (text.length > 2 && by)
                setSearch({ text, by });
        }, []),
        filter: useCallback(v => {
            if (v && Object.keys(v).length > 0) // not empty object
                setFilter(v);
        }, [])
    };
}